<template>
	<div
		ref="post_tv_manage_list"
		class=" size-px-14 color-gray02 flex-column" :class="items.length > 0 ? 'pb-30' : 'full-height'"
	>

		<div class="mt-50 box-shadow justify-space-between items-center">
			<div class=" justify-space-between items-center flex-1">
				<div class="pa-10-20">
					<input v-model="item_search.srchtext" :placeholder="$language.tv.txt_tv_list_search"  class="flex-1 search-shorts"/>
				</div>
				<button
					v-if="item_search.srchtext.length > 0"
					@click="item_search.srchtext = ''"
				><img :src="require('@/assets/image/btn_del.svg')" /></button>
			</div>
			<button class="mr-5"><img :src="require('@/assets/image/btn_search.svg')" class="width-80" /></button>
		</div>
		<div class="full-height flex-column ">
			<div
				v-if="view_type != 'episode'"
				class="pa-20 justify-center gap-10 size-px-12"
			>
				<button
					class="btn-tv radius-15 "
					:class="view_type == 'series' ? 'btn-blue' : 'btn-blue-outline'"
					@click="view_type = 'series'; getData()"
				>{{ $language.tv.btn_series }} <!-- 시리즈 --></button>
				<button
					class="btn-tv radius-15 "
					:class="view_type == 'alone' ? 'btn-blue' : 'btn-blue-outline'"
					@click="view_type = 'alone'; getData()"
				>{{ $language.tv.btn_alone }} <!-- 단편 --></button>
			</div>

			<div
				v-if="items.length > 0"
				class=" full-height flex-column "
				:class="view_type == 'episode' ? 'mt-20' : ''"
			>
				<div class="prl-20 size-px-14 color-gray-dark02 font-weight-500">{{ $language.tv.txt_tv_list_count}} {{ view_type == 'series' ? $language.tv.btn_series : $language.tv.txt_tv_list_movie}} {{ items.length | makeComma}} 개<!-- 등록된 동영상 --></div>
				<ul class="mt-20 prl-20 full-height ">
					<li
						v-for="(item, index) in list_items"
						:key="'item_' + index"
						class="mb-15"
					>
						<div
							class="justify-space-between gap-10" style="height: 80px"
						>
							<div class=" position-relative position-relative ">
								<template
									v-if="view_type == 'series'"
								>
									<div class="box radius-10 box-120-80" @click="toEpisode(item)">
										<img :src="item.cover_img" class="object-cover" @error="$bus.$emit('onErrorImage', $event)" />
									</div>
								</template>

								<template
									v-else
								>
									<div class="box radius-10 box-120-80">
										<img :src="item.post_file_list[0].post_appendix_file_org_url" class="object-cover" @error="$bus.$emit('onErrorImage', $event)" />
									</div>
									<div
										v-if="item.post_file_list[1] && item.post_file_list[1].post_appendix_file_transform_info && item.post_file_list[1].post_appendix_file_transform_info.transform_error_code == 'CA00900001'"
										class="position-absolute-full flex-column justify-center items-center text-white"
									>
										<div class="position-absolute-full" :class="'shorts-status-' + item.post_state_code"></div>
										<div class="position-relative text-center">
											<img :src="require('@/assets/image/icon_clock.svg')" />
											<div class="mt-10">{{ $language.shorts.subject_ing}} <!-- 게시중 --></div>
										</div>
									</div>
									<div
										v-else-if="item.post_file_list[1] && item.post_file_list[1].post_appendix_file_transform_info && item.post_file_list[1].post_appendix_file_transform_info.transform_error_code == 'LE00100003'"
										class="position-absolute-full flex-column justify-center items-center text-red"
										@click="onDelete(item)"
									>
										<div class="position-absolute-full" :class="'shorts-status-' + item.post_state_code"></div>
										<div class="position-relative text-center">
											<img :src="require('@/assets/image/icon_close_circle_red.svg')" />
											<div class="mt-10 color-red">{{ $language.shorts.subject_fail}} <!-- 게시 실패 --></div>
										</div>
									</div>

								</template>
							</div>
							<div class="flex-1 flex-column  full-height">
								<div class="size-px-13 full-height font-weight-500 color-gray-dark02 white-space">{{ item.title | maxLength(30, '...')}}</div>
								<div class="mt-auto size-px-12">{{ $date.getTimeStory(item.registdate)}}</div>
							</div>
							<div>
								<button
									class=""
									@click="onMore(item)"
								><img :src="require('@/assets/image/btn_postset.png')" class="width-50"/></button></div>
						</div>
					</li>
				</ul>
			</div>
			<Empty
				v-else

				class="full-height"
			></Empty>
		</div>

		<div style="position: fixed; right: 20px; bottom: 20px;">
			<button
				@click="toItem"
			>
				<div class="cartel_write_btn">
					<span class="hide">{{ $language.word.write }}</span>
				</div>
			</button>
		</div>

		<PopupHalf
			v-if="is_on_more"
			:user="user"
			:title="title_half"

			@cancel="is_on_more = false"
		>
			<template
				v-slot:half_items
			>
				<li class="pb-10" @click="toEdit">{{ $language.common.modify }} <!--  수정 --></li>
				<li @click="onDelete()">{{ $language.common.delete }} <!-- 삭제 --></li>
			</template>
		</PopupHalf>

		<Popup_confirm
			v-if="is_on_delete"

			@cancel="is_on_delete = false"
			@click="postDelete"
		>
			<template v-slot:title>{{ title_delete  }}</template>
			<template
				v-slot:main-txt
			>{{ txt_main_delete  }}</template>
			<template
				v-slot:sub-txt
			>{{ txt_sub_delete  }}</template>

		</Popup_confirm>
		
		<ScrollPagination
			v-if="$refs.post_tv_manage_list"
			:body="$refs.post_tv_manage_list"
			:item_search="item_search"
			:max="max"
			:is_add="is_add"

			@change="getSearchData"
		></ScrollPagination>
	</div>
</template>

<script>
import Empty from "@/view/Layout/Empty";
import PopupHalf from "@/view/Layout/PopupHalf";
import Popup_confirm from "@/view/Layout/PopupConfirm";
import ScrollPagination from "@/components/ScrollPagination";
export default {
	name: 'MafiaTvManageList'
	, components: {ScrollPagination, Popup_confirm, PopupHalf, Empty}
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: 'MafiaTvManageList'
				, title: this.$language.tv.subject_tv_list
				, type: 'cartel_sub'
				, not_footer: true
				, from: {
					name: 'MafiaTvManage'
					, params: {
						idx: this.$route.params.idx
					}
				}
			}
			, item_search: {
				srchtext: ''
				, page_number: 1
				, pagerecnum: 10
			}
			, items: [

			]
			, item_more: {}
			, is_on_more: false
			, is_on_delete: false
			, view_type: 'series'
			, item_search_episode: {
				page_number: 1
				, pagerecnum: 10
			}
			, item_series: {

			}
			, max: false
			, is_add: true
		}

	}
	, computed: {
		list_items: function(){
			return this.items.filter( (item) => {
				if(this.view_type == 'series'){
					item.cover_img = item?.post_file_list[0]?.post_appendix_file_org_url ? item?.post_file_list[0]?.post_appendix_file_org_url : '1'
				}else{
					item.post_file_list.filter( (file) => {
						if(file.post_appendix_file_div_code == 'CA01000001'){
							item.cover_img = file.post_appendix_file_org_url
						}
					})
				}
				return item
			})
		}
		, title_half: function(){
			let t = this.$language.notice.article
			if(this.view_type == 'series'){
				t = this.$language.tv.title_tv_series
			}
			return t
		}
		, title_delete: function(){
			let t = this.$language.confirm.delete_bbs.title
			if(this.view_type == 'series'){
				t = this.$language.tv.title_tv_series_delete
			}
			return t
		}
		, txt_main_delete: function(){
			let t = this.$language.confirm.delete_bbs.main

			if(this.view_type == 'series'){
				t = this.$language.tv.txt_tv_series_delete
			}
			return t
		}
		, txt_sub_delete: function(){
			let t = this.$language.confirm.delete_bbs.sub

			if(this.view_type == 'series'){
				t = this.$language.tv.txt_tv_series_delete_sub
			}
			return t
		}
	}
	, methods: {
		getData: async function(){
			try {
				this.is_add = false
				this.$bus.$emit('on', true)
				let url = this.$api_url.api_path.get_cartel_tv_manage_list
				if(this.view_type == 'alone'){
					url = this.$api_url.api_path.get_cartel_tv_alone
				}
				const result = await this.$Request({
					method: 'post'
					, url: url
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: this.$route.params.b_id
						, catgy_code: this.view_type == 'alone' ? 'CA04300003' : 'CA04300002'
						, post_level: this.view_type == 'alone' ? 0 : 1
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})

				if(result.success){
					if(this.item_search.page_number == 1){
						this.items = result.data.post_list
					}else{
						this.items = this.items.concat(result.data.post_list)
					}
					if(result.data.post_list.length > 0){
						this.max =false
					}else{
						this.max = true
					}
				}else{
					throw result.message
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
				this.is_add = true
			}
		}
		, onMore: function(item){
			this.is_on_more = true
			this.item_more = item
		}
		, toEdit: function(){

			if(this.view_type == 'series'){
				this.$emit('to', {name: 'MafiaTvSeries', params: {idx: this.$route.params.idx, code: this.$route.params.code, b_id: this.$route.params.b_id, type: this.view_type, p_id: this.item_more.bulletin_number}})
			}else{
				this.$bus.$emit('to', { name: 'MafiaTvEdit', params: {idx: this.$route.params.idx, code: this.$route.params.code, b_id: this.$route.params.b_id, bbs_id: this.item_more.bulletin_number}})
			}

		}
		, onDelete: function(item){
			if(item){
				this.item_more = item
			}
			this.is_on_delete = true
		}
		, postDelete: async function(){
			try {

				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_delete_bbs
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: this.item_more.board_number
						, bulletin_number: this.item_more.bulletin_number
					}
					, type: true
				})

				if (result.success) {
					await this.getData()
				} else {
					throw result.message
				}
			} catch (e) {
				console.log(e)
				this.$bus.$emit('notify', {type: 'error', message: e})
			} finally {
				this.$bus.$emit('on', false)
				this.is_on_delete = false
				this.is_on_more = false
			}
		}
		, toItem: function(){
			this.$bus.$emit('to', { name: 'MafiaTvUpload', params: { idx: this.$route.params.idx, code: this.$route.params.code, b_id: this.$route.params.b_id}})

			/*
			switch (this.view_type){
				case 'series':
					this.$emit('to', {name: 'MafiaTvSeries', params: {idx: this.$route.params.idx, code: this.$route.params.b_code, b_id: this.$route.params.b_id, type: this.view_type}})
					break;
				case 'alone':
					this.$emit('to', {name: 'MafiaTvItem', params: {idx: this.$route.params.idx, code: this.$route.params.b_code, b_id: this.$route.params.b_id, type: this.view_type}})
					break;
				case 'episode':
					this.$emit('to', {name: 'MafiaTvItem', params: {idx: this.$route.params.idx, code: this.$route.params.b_code, b_id: this.$route.params.b_id, p_id: this.item_series.bulletin_number, type: this.view_type}})
					break;
			}
			 */
		}
		, getEpisode: async function(series){
			try {

				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_tv_episode_list
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: series.board_number
						, parents_post_number: series.bulletin_number
						, page_number: this.item_search_episode.page_number
						, pagerecnum: this.item_search_episode.pagerecnum
					}
					, type: true
				})

				if (result.success) {
					this.items = result.data.post_list
				} else {
					throw result.message
				}
			} catch (e) {
				console.log(e)
				this.$bus.$emit('notify', {type: 'error', message: e})
			} finally {
				this.$bus.$emit('on', false)
				this.is_on_delete = false
				this.is_on_more = false
			}
		}
		, toEpisode: function(item){
			this.$bus.$emit('to', { name: 'MafiaTvManageEpisode', params: { idx: this.$route.params.idx, code: this.$route.params.code, b_id: this.$route.params.b_id, p_id: item.bulletin_number }})
		}
		, getSearchData: function(page){
			this.item_search.page_number = page
			this.getData()
		}
	}
	, created() {
		this.$bus.$emit('onLoad', this.program)
		this.getData()

		this.$bus.$on('callBack', (call) => {
			if(call.type == this.program.name){
				this.view_type = 'series';
				this.getData()
			}
		})
	}
}
</script>

<style>
	.box-120-80 {
		width: 120px; height: 80px;
		border-radius: 8px;
	}
	.box-130-70 {
		width: 130px; height: 70px;
		border-radius: 8px;
	}

	.white-space {
		white-space: normal;
		text-overflow: ellipsis;
		word-break: break-all;
		overflow: hidden
	}


	.btn-tv{
		width: 62px;
		height: 30px;
		padding: 9px 14px;
		display: flex;
		justify-content: center;
		align-items: center;

		color: var(--blue02, #293097);
		text-align: center;
		font-family: "Noto Sans CJK KR";
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: 100%; /* 12px */
	}

	.btn-blue {
		background-color: var(--blue02);
		color: var(--white);
	}
	.btn-blue-outline {
		border: 1px solid var(--blue02);
		color: var(--blue02);
	}
</style>